<template>
  <div v-if="dataCart" class="payment-info-bag">
    <h3 class="mb-4 text-center">Resumen de compra</h3>
    <div v-if="faltanCampos" class="text-center mb-3">
      <span style="color: tomato;">Faltan campos por llenar</span>
    </div>
    <div v-if="!dataCart.cupon.codigo && stepPayment < 5" class="input-group">
      <input
        v-model="codCupon"
        type="text"
        class="input-cupon"
        placeholder="Cupón de descuento"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
      />
      <span
        id="basic-addon2"
        class="btn-cupon"
        @click="!loading ? validateCupon() : ''"
      >
        <div v-if="!loading">Validar</div>
        <div v-else class="d-flex justify-content-center">
          <div class="loader" />
        </div>
      </span>
      <p v-if="msgErrorCupon" class="msgError fs-6 mx-auto mt-2">
        {{ msgErrorCupon }}
      </p>
    </div>
    <div class="info-bag-prices mb-3">
      <div class="data-bag-price">
        <p class="">Subtotal:</p>
        <p class="">
          ${{
            new Intl.NumberFormat('de-DE').format(dataCart.carrito_subtotal)
          }}
        </p>
      </div>
      <calc-shipping v-if="showShipping" class="calc-shipping-transition" />
      <div v-if="dataCart.cupon.codigo" class="mb-4">
        <div class="content-cupon-box">
          <div class="box-delete-cupon">
            <img
              v-if="stepPayment < 5"
              class="me-2 p-0 mx-2"
              src="@/assets/icons/delete-gray-icon.svg"
              alt="boton cerrar"
              @click="removeCupon()"
            />
            <span class="mb-1"><b>Cupón:</b> {{ dataCart.cupon.codigo }}</span>
            <img
              v-if="dataCart.cupon.tooltip_titulo"
              id="cupon-popover"
              v-b-popover.hover.left="dataCart.cupon.tooltip_descripcion"
              class="mx-2"
              src="@/assets/icons/icon-info.svg"
            />
          </div>
          <span class="mb-1"
            >-${{
              new Intl.NumberFormat('de-DE').format(
                dataCart.carrito_cupon_descuento,
              )
            }}</span
          >
        </div>
      </div>
      <div v-if="dataCart.envio" class="data-bag-price">
        <p class="mb-0">Envio:</p>
        <p
          v-if="dataCart.infoEstrategiaVentas"
          :style="
            'cursor: pointer; color: #654062; font-family: PT Sans Bold; text-transform: capitalize;'
          "
        >
          {{ dataCart.infoEstrategiaVentas.tipo_estrategia }}
          <img
            v-if="true"
            id="cupon-popover"
            v-b-popover.hover.left="
              dataCart.infoEstrategiaVentas.tooltip_descripcion
            "
            src="@/assets/icons/icon-info.svg"
          />
        </p>
        <p v-else class="">
          ${{ new Intl.NumberFormat('de-DE').format(dataCart.envio.total) }}
        </p>
      </div>
      <div class="data-bag-price">
        <p class="">Descuentos:</p>
        <p class="">
          ${{
            new Intl.NumberFormat('de-DE').format(dataCart.carrito_descuento)
          }}
        </p>
      </div>
      <div
        v-if="dataCart.carrito_bono_codigo && dataCart.carrito_bono_valor"
        class="mb-3"
      >
        <div class="content-cupon-box">
          <div class="box-delete-cupon">
            <img
              v-if="stepPayment < 5"
              class="me-2 p-0"
              src="@/assets/icons/delete-gray-icon.svg"
              alt="boton cerrar"
              @click="removeBono()"
            />
            <span class="mb-1"
              ><b>Saldo a favor:</b> {{ dataCart.carrito_bono_codigo }}</span
            >
          </div>
          <span class="mb-1"
            >-${{
              new Intl.NumberFormat('de-DE').format(
                dataCart.carrito_bono_valor_aplicado,
              )
            }}</span
          >
        </div>
      </div>
      <hr />
      <div class="data-bag-price">
        <p class="">Total:</p>
        <p>
          ${{ new Intl.NumberFormat('de-DE').format(dataCart.carrito_total) }}
        </p>
      </div>
    </div>
    <h3 class="mb-4 text-center">Productos</h3>
    <payment-products />
    <div class="d-flex justify-content-center align-items-center mt-3">
      <p class="bold mb-0">¿Necesitas ayuda?</p>
      <a
        class="ps-2"
        href="tel:018000423550"
        style="color: black; font-size: 1.2rem"
      >
        01 8000 423550</a
      >
    </div>
    <b-modal
      id="modal-cupon"
      v-model="modalCupon"
      centered
      hide-footer
      hide-header
    >
      <div class="p-3">
        <h3>
          <b>{{ dataCart.cupon.tooltip_titulo }}</b>
        </h3>
        <p class="fs-6">{{ dataCart.cupon.tooltip_descripcion }}</p>
        <p>
          <a target="_blank" :href="dataCart.cupon.link_pagina_condiciones">
            ver más
          </a>
        </p>
        <button class="btn-primary px-5" @click="modalCupon = false">
          Entendido
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import PaymentProducts from '@/components/Payment/PaymentProducts.vue'
import CalcShipping from '@/components/Templates/CalcShipping.vue'

import { mapState, mapActions } from 'vuex'
export default {
  components: { PaymentProducts, CalcShipping },
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
    validateInfo: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      showShipping: false,
      codCupon: '',
      loading: false,
      faltanCampos: false,
      modalCupon: false,
    }
  },
  computed: {
    ...mapState('cart', [
      'dataCart',
      'productsCart',
      'updateValidations',
      'validateDataUser',
      'validateAddres',
      'dataCart',
      'msgErrorCupon',
    ]),
    /* eslint-disable */
    validateEmail: function() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.dataCart.cliente_correo)
    },
    validateDNI: function() {
      if (this.dataCart.tipodoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.dataCart.cliente_documento) &&
          this.dataCart.cliente_documento.length >= 7 &&
          this.dataCart.cliente_documento.length <= 10
        )
      } else if (this.dataCart.tipodoc == 'CE') {
        const re2 = /([0-9]){6}/
        return (
          re2.test(this.dataCart.cliente_documento) &&
          this.dataCart.cliente_documento == 6
        )
      } else if (this.dataCart.tipodoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){5}/
        return (
          re3.test(this.dataCart.cliente_documento) &&
          this.dataCart.cliente_documento.length >= 5
        )
      } else if (this.dataCart.tipodoc == 'NIT') {
        return this.dataCart.cliente_documento.length == 9
      }
      return false
    },
    validatePhone: function() {
      const re = /^3[0-9]{9}/
      return (
        re.test(this.dataCart.cliente_celular) &&
        this.dataCart.cliente_celular.length < 11
      )
    },
  },
  watch: {
    validateInfo() {
      this.nextStepAndValidations()
    },
  },
  methods: {
    ...mapActions('cart', [
      'updateInfoCart',
      'applyCupon',
      'removeCupon',
      'removeBono',
    ]),
    async nextStepAndValidations() {
      this.faltanCampos = false
      this.$store.state.cart.faltanCampos = false
      if (this.dataCart.carrito_editar == 0 || this.dataCart.idDir) {
        this.$store.state.cart.validateAddres = true
      } else {
        if (
          this.dataCart.cliente_direccion.length > 4 &&
          this.dataCart.cliente_barrio.length > 4 &&
          this.dataCart.cliente_departamento &&
          this.dataCart.ciudad_id
        ) {
          this.$store.state.cart.validateAddres = true
        } else {
          this.$store.state.cart.validateAddres = false
        }
      }
      if (
        this.dataCart.carrito_editar == 0 ||
        (this.validateDNI &&
          this.validateEmail &&
          this.validatePhone &&
          this.dataCart.cliente_nombre.length >= 4 &&
          this.dataCart.cliente_apellidos.length >= 4 &&
          this.dataCart.tipodoc &&
          this.dataCart.isAdult)
      ) {
        this.$store.state.cart.validateDataUser = true
      } else {
        this.$store.state.cart.validateDataUser = false
      }
      this.$store.state.cart.updateValidations += 1
      if (this.validateDataUser && this.validateAddres) {
        this.$store.state.loaderDerek = true
        await this.updateInfoCart().then(() => {
          this.$emit('nextStep', 1)
          this.$store.state.loaderDerek = false
        })
      } else {
        this.faltanCampos = true
        this.$store.state.cart.faltanCampos = true
      }
    },
    async validateCupon() {
      if (this.codCupon) {
        this.loading = true
        await this.applyCupon({ discountCode: this.codCupon }).then(() => {
          this.loading = false
          if (!this.msgErrorCupon) {
            this.codCupon = ''
          }
        })
        if (this.dataCart.cupon.tooltip_titulo) {
          this.modalCupon = true
        }
      }
    },
  },
}
</script>
