<template>
  <div class="col-12 px-3">
    <data-summary-payment
      :data-response="dataSummaryPayment.pedido"
      :products="dataSummaryPayment.pedido.productos"
    />
  </div>
</template>
<script>
import DataSummaryPayment from '@/components/Payment/DataSummaryPayment.vue'
import { mapState, mapActions } from 'vuex'
export default {
  components: { DataSummaryPayment },
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      range: null,
      mensaje: '',
      sendedPoll: false,
      styleRange: [
        'left: calc(-50% + 17px);',
        'left: calc(-40% + 15px);',
        'left: calc(-30% + 10px);',
        'left: calc(-20% + 6px);',
        'left: calc(-10% + 3px);',
        'left: 0;',
        'left: calc(10% - 3px);',
        'left: calc(20% - 6px);',
        'left: calc(30% - 10px);',
        'left: calc(40% - 15px);',
        'left: calc(50% - 17px);',
      ],
      errorEncuesta: false,
    }
  },
  computed: {
    ...mapState('cart', ['dataSummaryPayment', 'errorSurvey', 'productsCart']),
    valueRatingMovil() {
      return this.styleRange[this.range]
    },
  },
  created() {
    window.scrollTo(0, 0)
    window.sendinblue.identify(
      this.dataSummaryPayment.pedido.carrito.cliente_correo,
    )
    window.sendinblue.track(
      'purchase_derek',
      {
        email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
      },
      {
        id: this.dataSummaryPayment.pedido.pedido_id,
        data: {
          revenue: this.dataSummaryPayment.pedido.carrito.carrito_total,
          venta_cruzada: this.dataSummaryPayment.dataTrackPurchaseendinblue
            .venta_cruzada,
          venta_genero: this.dataSummaryPayment.dataTrackPurchaseendinblue
            .genero_id,
          items: this.dataSummaryPayment.dataTrackPurchaseendinblue
            .items_carrito,
          items_complementarios: this.dataSummaryPayment
            .dataTrackPurchaseendinblue.items_complementarios,
        },
      },
    )
  },
  beforeDestroy() {
    this.$store.state.cart.dataCart = null
    this.$store.state.cart.productsCart = null
  },
  methods: {
    ...mapActions('cart', ['RegisterPollCheckout']),
    async registerPoll() {
      this.errorEncuesta = false
      if (this.range) {
        await this.RegisterPollCheckout({
          pedido_id: this.dataSummaryPayment.pedido.pedido
            .pedido_reference_sale,
          puntaje: this.range,
          mensaje: this.mensaje,
        }).then(() => {
          if (!this.errorSurvey) {
            this.sendedPoll = true
          }
        })
      } else {
        this.errorEncuesta = true
      }
    },
  },
}
</script>
